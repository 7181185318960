import React, {useEffect, useState} from 'react';
import { Avatar, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormDialog from '../general/FormDialog';
import UpdateUserForm from './UpdateUserForm';
import axios from "axios";

const UserTable = ({users, refetch}) => {
    const [open, setOpen] = useState(false);
    const [updateUser, setUpdateUser] = useState(undefined);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        refetch();
    };

    async function deleteUser(user) {
        try {
            await axios.delete("users/" + user._id);
            refetch();
        } catch (error) {
            console.error('Error deleting user', error);
        }
    }

    return (
        <>
            <FormDialog open={open} handleClose={handleClose}>
                <UpdateUserForm user={updateUser} handleClose={handleClose} />
            </FormDialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <Avatar>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</Avatar>
                                        <Typography ml={1}>{`${user.firstName} ${user.lastName}`}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box mr={1}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<EditIcon />}
                                                onClick={() => {
                                                    setUpdateUser(user);
                                                    handleClickOpen();
                                                }}
                                            >
                                                Ändern
                                            </Button>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => {
                                                deleteUser(user);
                                            }}
                                        >
                                            Löschen
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UserTable;
import React, {useState, useContext} from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';
import {ContractState, isStateAbove} from '../../../enums/ContractState';
import {ContractContext} from "../../../provider/ContractProvider";
import {getNewestLLMReview} from "../../../helper/contractHelpers";

const ClassificationAndChangeReset = () => {
    const { contract, runLLM, resetContract } = useContext(ContractContext);
    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState(() => {});

    function anyClausesWithoutLLMReview() {
        return contract.paragraphs.some(paragraph =>
            paragraph.clauses.some(clause => getNewestLLMReview(clause)==null)
        );
    }

    const markAsFinished = async () => {
        resetContract(ContractState.FINISHED_REVIEW);
    };

    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setActionToConfirm(() => {});
    };

    return (
        <Card sx={{ marginLeft: 1, p: 1.5, width: '100%' }}>
            <ConfirmActionDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                actionToConfirm={actionToConfirm}
            />
            <Box display="flex" justifyContent="right" sx={{ width: '100%', gap: 1 }}>
                <Button
                    variant="contained"
                    onClick={runLLM}
                    disabled={!anyClausesWithoutLLMReview()}
                >
                    <CachedIcon />
                    <Typography ml={2} variant="body2">
                        KI-Analyse
                    </Typography>
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleOpenDialog(markAsFinished)}
                    color="success"
                    disabled={isStateAbove(contract.state, ContractState.FINISHED_REVIEW)}
                >
                    <CheckCircleOutlineIcon />
                    <Typography ml={2} variant="body2">
                        Anwaltlich abgenommen
                    </Typography>
                </Button>
            </Box>
        </Card>
    );
};

export default ClassificationAndChangeReset;

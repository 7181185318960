import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper, useTheme } from '@mui/material';
import React from 'react';

const SearchBar = ({
                       searchString,
                       changeSearchString
                   }) => {
    const theme = useTheme();

    const handleInputChange = (event) => {
        changeSearchString(event.target.value);
    };

    return (
        <Paper component="form" sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: 'none'
        }}>
            <InputBase sx={{
                ml: 1,
                flex: 1
            }} placeholder="Suche eingeben" inputProps={{ 'aria-label': 'Suche eingeben' }} value={searchString} onChange={handleInputChange} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }} />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="Suche">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
};

export default SearchBar;
import { Box, Button, Card, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, {useContext} from 'react';
import DownloadContractButton from './DownloadContractButton';
import {ContractState, isStateAbove} from '../../enums/ContractState';
import {ContractContext} from "../../provider/ContractProvider";

const steps = ['Bewertung', 'Neufassung'];

const WorkflowStepper = ({activeStep, handleNext, handleBack }) => {
    const { contract } = useContext(ContractContext);

    return (
        <Card>
            <Box sx={{
                margin: 2
            }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>))}
                </Stepper>

                <Box>
                    {activeStep === steps.length ? (
                        <Box>
                            <Typography>All steps completed</Typography>
                        </Box>) : (
                        <Box mt={2}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button color="inherit" disabled={activeStep === 0} onClick={() => {
                                    handleBack();
                                }}>
                                    <Typography variant="body1"> Back </Typography>
                                </Button> {activeStep < steps.length - 1 ? (
                                <Button disabled={!isStateAbove(contract.state, ContractState.FINISHED_REVIEW)} onClick={() => {
                                    handleNext();
                                }} variant="contained">
                                    <Typography variant="body1">Next</Typography>
                                </Button>) : (
                                <DownloadContractButton />)}
                            </Box>
                        </Box>)}
                </Box>
            </Box>
        </Card>
    );
};

export default WorkflowStepper;

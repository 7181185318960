import PersonIcon from '@mui/icons-material/Person';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import Box from '@mui/material/Box';
import React from 'react';
import {getNewestHumanReview, getNewestLLMReview} from "../../../helper/contractHelpers";

const TrafficLightIndication = ({ clause }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%'
        }}>
            {/* Box 1 */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
                marginBottom: 0.5
            }}>
                <SmartToyOutlinedIcon />
                <Box sx={{
                    height: '20px',
                    width: '20px',
                    marginLeft: 1,
                    borderRadius: '50%',
                    backgroundColor: `assessment.${getNewestLLMReview(clause)?.assessment}`,
                }} />
            </Box>

            {/* Box 2 */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%'
            }}>
                <PersonIcon />
                <Box sx={{
                    height: '20px',
                    width: '20px',
                    marginLeft: 1,
                    borderRadius: '50%',
                    backgroundColor: `assessment.${getNewestHumanReview(clause)?.assessment}`
                }} />
            </Box>
        </Box>
    );
};

export default TrafficLightIndication;
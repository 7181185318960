import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUploadCard = ({ handleFileChange }) => {
    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles);
    }, [handleFileChange]);

    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        onDrop
    });

    const dropzoneStyles = {
        border: '1px dashed #cccccc',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragActive ? '#dcf2ff' : '#f4f4f4'
    };

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <div>
                <FileDownloadIcon fontSize="large" />
                <Typography> Ziehe die Datei hierhin oder klicke, um sie auszuwählen </Typography>
            </div>
        </div>
    );
};

export default FileUploadCard;
import { Box, Typography } from '@mui/material';
import React from 'react';
import {getAppliedRuleVersion} from "../../../helper/contractHelpers";

const ClassificationLawyer = ({ review }) => {
    return (
        <Box
            sx={{
                marginTop: 1.5,
                marginBottom: 1.5,
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Box
                sx={{
                    minWidth: '20px',
                    height: '20px',
                    marginRight: 2,
                    borderRadius: '50%',
                    backgroundColor: `assessment.${review.assessment}`,
                }}
            />
            <Box>
                <Typography
                    sx={{
                        marginBottom: 0.5,
                        fontWeight: 'bold',
                    }}
                >
                    {review.author.name}
                </Typography>
                <Typography>
                    {review.explanation}
                    {review.hurt_rules.length > 0 && (
                        <>
                            <br />
                            <br />
                        </>
                    )}
                    {review.hurt_rules.map((rule, index) => (
                            <li key={index}>
                                {index !== 0 ? '\n' : ' '}
                                <span>{getAppliedRuleVersion(rule, review.createdAt).text}</span>
                            </li>
                        ))}
                </Typography>
            </Box>
        </Box>
    );
};

export default ClassificationLawyer;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./provider/AuthProvider";
import UserOverview from "./components/userManagement/UserOverview";
import ContractsOverview from "./components/contractManagement/ContractsOverview";
import TemplateClauseOverview from "./components/templateClauseManagement/TemplateClauseOverview";
import RuleOverview from "./components/ruleManagement/RuleOverview";
import TopicOverview from "./components/topicManagement/TopicOverview";
import ContractWorkflow from "./components/contractManagement/ContractWorkflow";
import AnalysisPage from "./components/analysis/AnalysisPage";
import PageNotFound from "./components/general/PageNotFound";
import RequireAdmin from "./provider/RequireAdmin";
import Layout from "./components/general/Layout";
import ContractProvider from "./provider/ContractProvider";

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                    <Routes>
                        <Route element={<Layout />} >
                            <Route path="/" element={<ContractsOverview />} />
                            <Route path="/contracts" element={<ContractsOverview />} />
                            <Route path="/contracts/:contractId" element={
                                <ContractProvider>
                                    <ContractWorkflow/>
                                </ContractProvider>
                            } />
                            <Route path="/analysis" element={<AnalysisPage />} />

                            <Route path="/templateclauses" element={<RequireAdmin><TemplateClauseOverview /></RequireAdmin>} />
                            <Route path="/rules" element={<RequireAdmin><RuleOverview /></RequireAdmin>} />
                            <Route path="/topics" element={<RequireAdmin><TopicOverview /></RequireAdmin>} />
                            <Route path="/users" element={<RequireAdmin><UserOverview /></RequireAdmin>} />

                            <Route path="*" element={<PageNotFound />} />
                        </Route>
                    </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;

import React, {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import RuleModal from "./RuleModal";

const RuleTable = ({ rules, handleEdit, handleArchive }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedRule, setSelectedRule] = React.useState(null);
    const [showArchived, setShowArchived] = useState(false);

    const handleModalOpen = (rule) => {
        setSelectedRule(rule);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedRule(null);
    };

    function getNewestVersion(rule){
        return rule.versions[rule.versions.length - 1];
    }

    return (
        <>
            <Button
                variant="outlined"
                startIcon={showArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                sx={{ my: 4 }}
                onClick={() => setShowArchived(!showArchived)}
                color={showArchived ? "primary" : "secondary"}
            >
                {showArchived ? "Aktuelle anzeigen" : "Archiv anzeigen"}
            </Button>
            <TableContainer component={Paper} sx={{ mb: '2em' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Titel
                            </TableCell>
                            <TableCell>
                                Thema
                            </TableCell>
                            <TableCell>
                                Regeln
                            </TableCell>
                            <TableCell>
                                Aktionen
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rules?.filter(rule=>rule.isArchived===showArchived).map((rule) => (
                            <TableRow key={rule._id}>
                                <TableCell>
                                    <Typography>{rule.title}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{getNewestVersion(rule).topic.title}</Typography>
                                </TableCell>
                                <TableCell>
                                    {getNewestVersion(rule).references?.length > 0 ? (
                                        <Accordion
                                            sx={{
                                                boxShadow: 'none',
                                                '&:before': {
                                                    display: 'none',
                                                },
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                sx={{
                                                    padding: 0,
                                                    minHeight: '30px',
                                                }}
                                            >
                                                <Typography color="primary">
                                                    {getNewestVersion(rule).text}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: '0' }}>
                                                {getNewestVersion(rule).references.map((reference, refIndex) => (
                                                    <Typography
                                                        key={refIndex}
                                                        sx={{
                                                            fontSize: '0.875rem',
                                                            color: 'text.primary',
                                                            marginLeft: 1
                                                        }}
                                                    >
                                                        - {reference}
                                                    </Typography>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                    ) : (
                                        <Typography style={{ whiteSpace: "pre-line", marginBottom: getNewestVersion(rule).references?.length ? 1 : 0 }}>
                                            {getNewestVersion(rule).text}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex" }}>
                                        <Tooltip title="Bearbeiten" placement="top">
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() => handleEdit(rule._id, rule)}
                                                sx={{
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: "blue",
                                                    },
                                                }}
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Versionen" placement="top">
                                            <Button
                                                variant="text"
                                                onClick={() => handleModalOpen(rule)}
                                                sx={{
                                                    color: "orange",
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: "darkorange",
                                                    },
                                                }}
                                            >
                                                <HistoryIcon />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={showArchived ? "Wiederherstellen" : "Archivieren"}>
                                            <Button
                                                variant="text"
                                                onClick={() => handleArchive(rule, !rule.isArchived)}
                                                sx={{
                                                    color: rule.isArchived ? "#3979d2" : "#d23939",
                                                    minWidth: "auto",
                                                    "&:hover": {
                                                        color: rule.isArchived ? "#23487a" : "#6e1d1d",
                                                    },
                                                }}
                                            >
                                                {rule.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <RuleModal
                open={modalOpen}
                onClose={handleModalClose}
                rule={selectedRule}
            />
        </>
    );
};

export default RuleTable;

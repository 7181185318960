import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import OptionsMenu from '../general/OptionsMenu';
import WorkflowStateBadge from './WorkflowStateBadge';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

const ContractItem = ({ contract, handleReset, handleDelete }) => {
    const [pdf, setPDF] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const options = {
                    url: process.env.REACT_APP_API_BASE_URL + '/contracts/files/' + contract.file,
                    withCredentials: true,
                    httpHeaders: {
                        mode: 'cors',
                        authorization: 'Bearer ' + localStorage.getItem("jwtToken"),
                    }
                };
                const loadingTask = pdfjsLib.getDocument(options);
                const loadedPdf = await loadingTask.promise;
                setPDF(loadedPdf);
            } catch (error) {
                console.error('Error while loading PDF: ', error);
            }
        };

        if (contract?.file) {
            loadPdf();
        }
    }, [contract]);

    useEffect(() => {
        const renderPage = async () => {
            if (!pdf || !containerRef.current) return;

            const page = await pdf.getPage(1);
            while (containerRef.current.firstChild) {
                containerRef.current.removeChild(containerRef.current.firstChild);
            }

            const canvas = document.createElement('canvas');
            containerRef.current.appendChild(canvas);

            const context = canvas.getContext('2d');
            const viewport = page.getViewport({ scale: containerRef.current.clientWidth / page.getViewport({ scale: 1 }).width });

            canvas.width = viewport.width;
            canvas.height = viewport.height;

            await page.render({ canvasContext: context, viewport }).promise;
        };

        renderPage();
    }, [pdf]);

    return (
        <Card
            sx={{
                cursor: 'pointer',
                boxShadow: 'none',
                height: '100%',
                maxHeight: '350px',
                '&:hover': {
                    bgcolor: '#ffffff99'
                }
            }}
        >
            <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                maxHeight: '-webkit-fill-available'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'start',
                    gap: 2,
                    mb: 2
                }}>
                    <Box width={'40%'} height={'auto'} sx={{ position: 'relative', overflow: 'hidden' }} ref={containerRef}></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '66%'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2
                        }}>
                            <Typography sx={{ fontWeight: 'bold', width: '100%' }}>
                                {`${new Date(contract?.createdAt).toLocaleDateString('de-DE', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: '2-digit'
                                })}`}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}>
                                <OptionsMenu contract={contract} handleReset={handleReset} handleDelete={handleDelete} />
                            </Box>
                        </Box>
                        <Typography variant="h6" mr={1} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontWeight: 'bold'
                        }}>
                            {contract?.title}
                        </Typography>
                        <Typography mb={1} color="text.secondary" sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {contract?.description}
                        </Typography>
                        <Typography mt={0.5} color="text.secondary">
                            {`${contract.owner.firstName} ${contract.owner.lastName}` || 'Kein Nutzer bekannt.'}
                        </Typography>
                    </Box>
                </Box>

                <WorkflowStateBadge contractState={contract.state} />
            </CardContent>
        </Card>
    );
};

export default ContractItem;

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

const ConfirmActionDialog = ({
                                 openDialog,
                                 handleCloseDialog,
                                 actionToConfirm
                             }) => {
    return (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{'Bestätigung erforderlich'}</DialogTitle>
            <DialogContent>
                <DialogContentText> Sind Sie sicher, dass Sie diese Aktion durchführen möchten? </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Abbrechen</Button>
                <Button onClick={() => {
                    actionToConfirm();
                    handleCloseDialog();
                }} color="primary" autoFocus> Bestätigen </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmActionDialog;

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, {useContext, useState} from 'react';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';
import {ContractContext} from "../../../provider/ContractProvider";

const OptionsMenuParagraph = ({ paragraph, handleEdit }) => {
    const { deleteParagraph, downgradeParagraph } = useContext(ContractContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState(() => {});

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setActionToConfirm(() => {});
    };

    const handleDowngrade = () => {
        handleOpenDialog(()=>{
            downgradeParagraph(paragraph._id);
        });
    };

    const handleDelete = () => {
        handleOpenDialog(()=>{
            deleteParagraph(paragraph._id);
        });
    };

    return (
        <>
            <ConfirmActionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} actionToConfirm={actionToConfirm} />
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} keepMounted>
                <MenuItem
                    onClick={handleEdit}
                    sx={{
                        borderRadius: '8px',
                        mx: 1,
                        pr: 0,
                    }}
                >
                    <ListItemText>
                        <Typography>Paragraph Text bearbeiten</Typography>
                    </ListItemText>
                    <ListItemIcon
                        sx={{
                            ml: 1,
                            mr: 0,
                        }}
                    >
                        <EditIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={handleDowngrade}
                    sx={{
                        borderRadius: '8px',
                        mx: 1,
                        pr: 0,
                    }}
                >
                    <ListItemText>
                        <Typography>Zu Klausel umwandeln</Typography>
                    </ListItemText>
                    <ListItemIcon
                        sx={{
                            ml: 1,
                            mr: 0,
                        }}
                    >
                        <FileDownloadIcon />
                    </ListItemIcon>
                </MenuItem>
                <MenuItem
                    onClick={handleDelete}
                    sx={{
                        borderRadius: '8px',
                        mx: 1,
                        pr: 0,
                    }}
                >
                    <ListItemText>
                        <Typography>Paragraph löschen</Typography>
                    </ListItemText>
                    <ListItemIcon
                        sx={{
                            ml: 1,
                            mr: 0,
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </>
    );
};

export default OptionsMenuParagraph;

import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip, Container,
} from "@mui/material";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";

const TopicOverview = () => {
    const [topics, setTopics] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentTopic, setCurrentTopic] = useState({
        title: "",
        isDeleted: false,
    });

    const fetchTopics = async () => {
        try {
            const response = await axios.get("/topics/");
            setTopics(response.data);
        } catch (error) {
            console.error('Failed to fetch topics:', error);
        }
    };
    useEffect(() => { fetchTopics() }, []);

    const handleDeleteButton = async (topic) => {
        try {
            await axios.delete(`/topics/${topic._id}`);
        } catch (error) {
            console.error('Failed to delete topic:', error);
        }
        fetchTopics();
    };

    const handleCreateNewButton = () => {
        setCurrentTopic({
            title: "",
            isDeleted: false,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveEntry = async () => {
        try {
            if (!currentTopic.title) return;
            if (currentTopic._id) {
                await axios.put(`/topics/${currentTopic._id}`, { title: currentTopic.title });
            } else {
                await axios.post("/topics/", { title: currentTopic.title });
            }
            handleClose();
        } catch (error) {
            console.error("Failed to save topic:", error);
        }
        fetchTopics();
    };

    const handleEditButton = (topic) => {
        setCurrentTopic({ ...topic });
        setOpen(true);
    };

    return (
        <Container sx={{mt: '2em'}}>
                <Typography variant="h4">Themen</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
                                {currentTopic._id ? "Thema bearbeiten" : "Neues Thema erstellen"}
                            </Typography>

                            <TextField
                                value={currentTopic.title}
                                label="Thema... (zB.: Vergütung)"
                                fullWidth
                                margin="normal"
                                sx={{
                                    width: '100%',
                                    marginBottom: '1rem',
                                    display: 'block',
                                    overflowWrap: 'break-word'
                                }}
                                onChange={(e) => {
                                    setCurrentTopic(prev => ({
                                        ...prev,
                                        title: e.target.value
                                    }));
                                }}
                            />
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={saveEntry}
                                fullWidth
                            >
                                Speichern
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ my: 4 }}
                            onClick={handleCreateNewButton}
                        >
                            Neues Thema
                        </Button>
                    </Box>
                </Box>

                <TableContainer component={Paper} sx={{ mb: '2em' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{
                                backgroundColor: "#fcfcfc",
                            }}>
                                <TableCell width="60%">
                                    <Box sx={{ textAlign: "left" }}>
                                        Thema
                                    </Box>
                                </TableCell>
                                <TableCell width="15%">
                                    <Box sx={{ textAlign: "left" }}>
                                        Prüfanleitungen
                                    </Box>
                                </TableCell>
                                <TableCell width="15%">
                                    <Box sx={{ textAlign: "left" }}>
                                        Musterklauseln
                                    </Box>
                                </TableCell>
                                <TableCell width="10%">
                                    <Box sx={{ textAlign: "right" }}></Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {topics && topics.map((topic) => (
                                <TableRow key={topic._id}>
                                    <TableCell>
                                        <Box sx={{ textAlign: "left" }}>
                                            <Typography>{topic.title}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ textAlign: "left" }}>
                                            <Typography>{topic.ruleCount}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ textAlign: "left" }}>
                                            <Typography>{topic.templateCount}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ textAlign: "right", display: "flex", justifyContent: "right" }}>
                                            <Tooltip title="Bearbeiten" placement="top">
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => handleEditButton(topic)}
                                                    sx={{
                                                        minWidth: "auto",
                                                        "&:hover": {
                                                            color: "blue",
                                                        },
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Löschen" placement="top">
                                                <span>
                                                    <Button
                                                        disabled={topic.ruleCount!==0 || topic.templateCount!==0}
                                                        variant="text"
                                                        onClick={() => handleDeleteButton(topic)}
                                                        sx={{
                                                            color: "#d23939",
                                                            minWidth: "auto",
                                                            "&:hover": {
                                                                color: "#6e1d1d",
                                                            },
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </Container>
    );
};

export default TopicOverview;

import {Assessment} from "../enums/Assessment";
import {getMostRelevantReview} from "./contractHelpers";

export function getHighlights(pageNumber, paragraphs, pageWidths, pageHeights) {

    const allClauses = paragraphs.flatMap(paragraph => paragraph.clauses);
    const highlights = [];

    allClauses.forEach(clause => {
        if (clause.pageNumber !== pageNumber) return;
        const rects = transformPolygonToRects(clause.polygons, clause.pageNumber);
        if (rects == null) return;

        const relevantReview = getMostRelevantReview(clause);

        let color = 'rgba(211, 211, 211, 0.5)';
        if(relevantReview) switch (relevantReview.assessment) {
            case Assessment.VOID:
                color = 'rgba(210, 77, 87, 0.3)';
                break;
            case Assessment.UNFAIR:
                color = 'rgba(226, 177, 60, 0.3)';
                break;
            case Assessment.VALID:
                color = 'rgba(141, 178, 85, 0.3)';
                break;
        }

        const highlight = {
            clauseId: clause._id,
            x: rects.x1,
            y: rects.y1,
            width: rects.width,
            height: rects.height,
            color: color
        };

        highlights.push(highlight);
    });

    function transformPolygonToRects(polygon, pageNumber) {
        if (polygon.length < 8) {
            console.error("POLYGON WRONG FORMAT " + polygon);
            return null;
        }
        const [x1, y1, , , x2, y2] = polygon;
        const pageWidthInInches = pageWidths[pageNumber - 1];
        const pageHeightInInches = pageHeights[pageNumber - 1];
        return {
            pageNumber,
            x1: (x1 / pageWidthInInches),
            y1: (y1 / pageHeightInInches),
            x2: (x2 / pageWidthInInches),
            y2: (y2 / pageHeightInInches),
            width: ((x2 / pageWidthInInches)) - ((x1 / pageWidthInInches)),
            height: ((y2 / pageHeightInInches)) - ((y1 / pageHeightInInches))
        };
    }

    return highlights;
}

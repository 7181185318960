import React, { useEffect, useMemo, useState } from 'react';
import {Box, Typography, Paper, Tabs, Tab, Container} from '@mui/material';
import MetaInformationTab from "./MetaInformation/MetaInformationTab";
import AiPerformanceTab from "./AiPerformance/AiPerformanceTab";

const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`analysis-tabpanel-${index}`}
        aria-labelledby={`analysis-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ pt: 3 }}>
                {children}
            </Box>
        )}
    </div>
);

const AnalysisPage = () => {
    const [tabValue, setTabValue] = useState(1);

    useEffect(() => {
        const fetchContracts = async () => {
            // Placeholder for contract fetching logic
        };

        fetchContracts();
    }, []);

    return (
        <Container sx={{mt: '2em'}}>
                <Typography variant="h4" gutterBottom>Analytics</Typography>

                <Box sx={{ width: '100%', mt: '2em' }}>
                    <Tabs
                        value={tabValue}
                        onChange={(_, newValue) => setTabValue(newValue)}
                        variant="fullWidth"
                    >
                        <Tab label="Meta-Informationen" />
                        <Tab label="KI-Leistung" />
                        <Tab label="Klausel-Neuprüfung" />
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <Container>
                        <MetaInformationTab></MetaInformationTab>
                    </Container>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Container>
                        <AiPerformanceTab></AiPerformanceTab>
                    </Container>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <Container>

                    </Container>
                </TabPanel>
        </Container>
    );
};

export default AnalysisPage;

import React, { useContext } from 'react';
import NoPermission from "../components/general/NoPermission";
import {AuthContext} from "./AuthProvider";

const RequireAdmin = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (!user || user.role !== 'admin') {
        // Show NoPermission component if the user is not an admin
        return <NoPermission />;
    }

    return children;
};

export default RequireAdmin;

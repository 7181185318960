import React from 'react';
import {Box, Typography} from '@mui/material';

const PageNotFound = () => {
    return (
        <Box maxWidth="sm" sx={{ mx: "auto", height: "100%", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
            <Typography variant="h2" color="primary" gutterBottom> 403 - Kein Zugriff </Typography>
            <Typography variant="body1" color="textSecondary"> Bitte beim Administrator melden. Sie haben hier keinen Zugriff.</Typography>
        </Box>);
}

export default PageNotFound;

import { Box, Grid } from '@mui/material';
import React, {useContext, useState} from 'react';
import ContractOcrDisplay from './classification/ContractOcrDisplay';
import ContractInfo from './ContractInfo';
import WorkflowStepper from './WorkflowStepper';
import AlternativePage from './alternatives/AlternativePage';
import {ContractContext} from "../../provider/ContractProvider";
import {ContractState, isStateAbove} from "../../enums/ContractState";

const ContractWorkflow = () => {
    const { contract } = useContext(ContractContext);
    const [activeStep, setActiveStep] = useState(isStateAbove(contract.state, ContractState.FINISHED_REVIEW) ? 1 : 0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2} justifyContent={'center'} my={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <ContractInfo />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <WorkflowStepper activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />
                </Grid>
            </Grid>
            {activeStep === 0 && <ContractOcrDisplay />}
            {activeStep === 1 && <AlternativePage />}
        </Box>
    );
};

export default ContractWorkflow;
import AddIcon from '@mui/icons-material/Add';
import {Box, Button, Container, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import FormDialog from '../general/FormDialog';
import CreateUserForm from './CreateUserForm';
import UserTable from './UserTable';
import axios from "axios";

const UserOverview = () => {
    const [users, setUsers] = useState(null);
    const [open, setOpen] = useState(false);

    const fetchUsers = async () => {
        try {
            const response = await axios.get("users/");
            setUsers(response.data);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };
    useEffect(()=>{fetchUsers()}, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        fetchUsers();
    };

    return (
        <Container sx={{mt: '2em'}}>
            <Typography variant="h4">Nutzerverwaltung</Typography>
            <FormDialog open={open} handleClose={handleClose}>
                <CreateUserForm handleClose={handleClose} />
            </FormDialog>
            <Button variant="outlined" startIcon={<AddIcon />} sx={{ my: 4 }} onClick={handleClickOpen}> Neuen Nutzer anlegen </Button>
            <UserTable users={users} refetch={fetchUsers} />
        </Container>
    );
};

export default UserOverview;

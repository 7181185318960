import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper } from '@mui/material';
import { Description, Rule, Grading, Topic } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ContractsOverTime from "./ContractsOverTime";
import RulesPerTopicChart from "./RulesPerTopicChart";

const MetaInformationTab = () => {
    const theme = useTheme();
    const [metaInfo, setMetaInfo] = useState({
        clauseCount: 0,
        contracts: [],
        rules: [],
        topics: []
    });

    useEffect(() => {
        const fetchMetaInformation = async () => {
            try {
                const response = await axios.get('/analytics/metaInformation');
                setMetaInfo(response.data);
            } catch (error) {
                console.error('Error fetching meta information:', error);
            }
        };

        fetchMetaInformation();
    }, []);

    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 3, flexWrap: "wrap" }}>
                <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 3, flex: '1 1 0', height: 'revert' }}>
                    <Description sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />
                    <Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>Anzahl Verträge</Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metaInfo.contracts.length}</Typography>
                    </Box>
                </Paper>
                <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 3, flex: '1 1 0', height: 'revert' }}>
                    <Grading sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />
                    <Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>Anzahl Klauseln</Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metaInfo.clauseCount}</Typography>
                    </Box>
                </Paper>
                <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 3, flex: '1 1 0', height: 'revert' }}>
                    <Rule sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />
                    <Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>Anzahl Prüfanleitungen</Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metaInfo.rules.length}</Typography>
                    </Box>
                </Paper>
                <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 3, flex: '1 1 0', height: 'revert' }}>
                    <Topic sx={{ backgroundColor: theme.palette.grey[200], padding: 2, borderRadius: 100 }} />
                    <Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ color: theme.palette.grey[400] }}>Anzahl Themen</Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metaInfo.topics.length}</Typography>
                    </Box>
                </Paper>
            </Box>
            <Box sx={{mt: '2em'}}>
                <ContractsOverTime contracts={metaInfo.contracts}></ContractsOverTime>
            </Box>
            <Box sx={{mt: '2em'}}>
                <RulesPerTopicChart topics={metaInfo.topics} rules={metaInfo.rules}></RulesPerTopicChart>
            </Box>
        </Box>
    );
};

export default MetaInformationTab;

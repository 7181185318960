import { Box, ThemeProvider } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { lightTheme } from '../../Theme';
import DrawerMenuBar from './DrawerMenuBar';
import { Toaster } from "react-hot-toast";

const Layout = () => {
    return (
        <ThemeProvider theme={lightTheme}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                minHeight: '100vh',
                backgroundColor: 'background.default'
            }}>
                <DrawerMenuBar />
                <Box component="main" sx={{
                    width: '100%',
                    height: '100vh',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: 3,
                    mx: 'auto',
                    maxWidth: '1500px',
                    color: (theme) => theme.palette.getContrastText(theme.palette.background.default)
                }}>
                    <Outlet />
                </Box>
            </Box>
            <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
        </ThemeProvider>
    );
};

export default Layout;

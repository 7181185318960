import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import LoadingComponent from "../components/general/LoadingComponent";
import { useParams } from "react-router-dom";
import { ContractState } from "../enums/ContractState";

export const ContractContext = createContext(null);

const ContractProvider = ({ children }) => {
    const { contractId } = useParams();
    const [contract, setContract] = useState(null);
    const allowedStates = [ContractState.LLM_CLASSIFICATION_DONE, ContractState.FINISHED_REVIEW, ContractState.FINISHED];

    useEffect(() => {
        if (!contract){
            fetchContract();
        } else if (!allowedStates.includes(contract.state)) {
            setTimeout(fetchContract, 2000);
        }
    }, [contract]);

    const fetchContract = async () => {
        try {
            const response = await axios.get(`/contracts/${contractId}`);
            const fetchedContract = response.data;

            setContract(fetchedContract);
        } catch (error) {
            console.error(error);
            setContract(null);
        }
    };

    const runLLM = async () => {
        try {
            await axios.patch(`/contracts/runLLM/${contractId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error deleting clause:", error);
            fetchContract();
        }
    };

    const resetContract = async (newState) => {
        try {
            await axios.patch(`/contracts/reset/${contractId}`, {targetState: newState});
            setContract(prevContract => {
                return {
                    ...prevContract,
                    state: newState,
                };
            });
        } catch (error) {
            console.error("Error deleting clause:", error);
            fetchContract();
        }
    };

    const addReview = async (newReview) => {
        try {
            const response = await axios.post(`/reviews/`, newReview);
            const createdReview = response.data;

            setContract(prevContract => {
                return {
                    ...prevContract,
                    paragraphs: prevContract.paragraphs.map(paragraph => ({
                        ...paragraph,
                        clauses: paragraph.clauses.map(clause => {
                            if (clause._id === createdReview.clause) {
                                return {
                                    ...clause,
                                    reviews: [...(clause.reviews || []), createdReview]
                                };
                            }
                            return clause;
                        })
                    }))
                };
            });
        } catch (error) {
            console.error(error);
            fetchContract();
        }
    };

    const downgradeParagraph = async (paragraphId) => {
        try {
            await axios.patch(`/paragraphs/downgrade/${paragraphId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error downgrading paragraph:", error);
            fetchContract();
        }
    };

    const changeParagraph = async (paragraphId, updatedContent) => {
        try {
            await axios.put(`/paragraphs/${paragraphId}`, { content: updatedContent });
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error changing Paragraph:", error);
            fetchContract();
        }
    };

    const deleteParagraph = async (paragraphId) => {
        try {
            await axios.delete(`/paragraphs/${paragraphId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error deleting Paragraph:", error);
            fetchContract();
        }
    };

    const mergeClause = async (clauseId) => {
        try {
            await axios.patch(`/clauses/merge/${clauseId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error merging clause:", error);
            fetchContract();
        }
    };

    const upgradeClause = async (clauseId) => {
        try {
            await axios.patch(`/clauses/upgrade/${clauseId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error upgrading clause:", error);
            fetchContract();
        }
    };

    const changeClause = async (clauseId, updatedContent) => {
        try {
            await axios.put(`/clauses/${clauseId}`, { content: updatedContent });
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error changing clause:", error);
            fetchContract();
        }
    };

    const deleteClause = async (clauseId) => {
        try {
            await axios.delete(`/clauses/${clauseId}`);
            await fetchContract(); // Refetch the contract
        } catch (error) {
            console.error("Error deleting clause:", error);
            fetchContract();
        }
    };

    const setAlternative = async (clauseId, templateClauseId, alternativeText) => {
        try {
            const response = await axios.put(`/alternatives/${clauseId}`, {
                templateClauseId: templateClauseId,
                alternativeText: alternativeText,
            });
            const updatedAlternative = response.data;

            setContract(prevContract => {
                return {
                    ...prevContract,
                    paragraphs: prevContract.paragraphs.map(paragraph => ({
                        ...paragraph,
                        clauses: paragraph.clauses.map(clause => {
                            if (clause._id === updatedAlternative.clause) {
                                return {
                                    ...clause,
                                    alternative: updatedAlternative
                                };
                            }
                            return clause;
                        })
                    }))
                };
            });
        } catch (error) {
            console.error("Error setting alternative:", error);
            fetchContract();
        }
    };

    if (!contract || !allowedStates.includes(contract.state)) return <LoadingComponent />;

    return (
        <ContractContext.Provider value={{
            contract,
            runLLM,
            resetContract,
            addReview,
            downgradeParagraph,
            changeParagraph,
            deleteParagraph,
            mergeClause,
            upgradeClause,
            changeClause,
            deleteClause,
            setAlternative,
        }}>
            {children}
        </ContractContext.Provider>
    );
};

export default ContractProvider;

import React from 'react';
import { useTheme } from '@mui/material/styles';
import Chart from 'react-apexcharts';

const ContractsOverTime = ({ contracts }) => {
    const theme = useTheme();

    const processChartData = () => {
        const countsByMonth = contracts.reduce((acc, contract) => {
            const date = new Date(contract.createdAt);
            const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            acc[month] = (acc[month] || 0) + 1;
            return acc;
        }, {});

        const sortedMonths = Object.keys(countsByMonth).sort();
        const counts = sortedMonths.map(month => countsByMonth[month]);

        return {
            series: [{ name: 'Contracts', data: counts }],
            options: {
                chart: {
                    type: 'line',
                    toolbar: { show: false }
                },
                xaxis: {
                    categories: sortedMonths,
                    title: { text: 'Month' }
                },
                yaxis: {
                    title: { text: 'Number of Contracts' }
                },
                colors: [theme.palette.primary.main],
                dataLabels: { enabled: false },
                stroke: { curve: 'smooth' },
                title: {
                    text: 'Contracts Created Over Time',
                    align: 'center'
                }
            }
        };
    };

    const chartData = processChartData();

    return (
        <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
        />
    );
};

export default ContractsOverTime;
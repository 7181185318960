import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Description from '@mui/icons-material/Description';
import { Autocomplete, Box, Button, CircularProgress, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import FileUploadCard from './FileUploadCard';
import toast from 'react-hot-toast';
import {industries} from "../../enums/industries";
import axios from "axios";

const UploadCard = ({addContract}) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [industry, setIndustry] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = useState(true);
    const [isIndustryValid, setIsIndustryValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
            setUploadedFileName(acceptedFiles[0].name);
        }
    };

    const handleSubmit = async () => {
        if (!title) {
            setIsTitleValid(false);
            return;
        }
        setIsTitleValid(true);

        if (!description) {
            setIsDescriptionValid(false);
            return;
        }
        setIsDescriptionValid(true);

        if (!industry) {
            setIsIndustryValid(false);
            return;
        }
        setIsIndustryValid(true);

        if (!file) {
            toast.error('Bitte wählen Sie eine PDF Datei aus.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('industry', industry);
        formData.append('file', file);

        try {
            const response = await axios.post('/contracts/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success("Der Vertrag wird jetzt bearbeitet. Das kann einige Minuten dauern.");
            setTitle("");
            setDescription("");
            setIndustry(null);
            setFile(null);
            addContract(response.data);
        } catch (error) {
            console.error('Error creating contract:', error);
        } finally {
            setUploadedFileName(null);
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 1,
            p: 1,
            flexGrow: 1
        }}>
            <TextField label="Titel" value={title} onChange={(e) => {
                setTitle(e.target.value);
            }} required error={!isTitleValid} helperText={!isTitleValid ? 'Titel erforderlich' : ''} fullWidth size="small" sx={{
                backgroundColor: 'background.default',
                "& fieldset": {
                    border: 'none'
                },
                borderRadius: '6px'
            }} />
            <TextField label="Beschreibung" value={description} onChange={(e) => {
                setDescription(e.target.value);
            }} required error={!isDescriptionValid} helperText={!isDescriptionValid ? 'Beschreibung erforderlich' : ''} fullWidth size="small" sx={{
                backgroundColor: 'background.default',
                "& fieldset": {
                    border: 'none'
                },
                borderRadius: '6px'
            }} />
            <Autocomplete options={industries} value={industry} onChange={(event, newValue) => setIndustry(newValue)} renderInput={(params) => (
                <TextField{...params} label="Branche" required error={!isIndustryValid} helperText={!isIndustryValid ? 'Branche erforderlich' : ''} fullWidth size="small" sx={{
                    backgroundColor: 'background.default',
                    "& fieldset": {
                        border: 'none'
                    },
                    borderRadius: '6px'
                }} />)} />
            <FileUploadCard handleFileChange={handleFileChange} /> {uploadedFileName && (
            <Box display="flex" alignItems="center">
                <Paper variant="outlined" style={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    borderStyle: 'dotted',
                    borderRadius: '8px'
                }}>
                    <Description style={{ marginRight: '10px' }} /> {uploadedFileName}
                </Paper>
            </Box>)}
            <Button variant="contained" startIcon={!isLoading &&
                <CloudUploadIcon />} disabled={isLoading} onClick={() => handleSubmit()} fullWidth sx={{
                mt: 1
            }}>
                {isLoading ?
                    <CircularProgress color="secondary" /> : 'Vertrag erstellen'}
            </Button>
        </Box>
    );
};

export default UploadCard;

import { Chip } from '@mui/material';
import React from 'react';

const WorkflowStateBadge = ({ contractState }) => {
    const stateProperties = {
        PDF_UPLOADED: {
            text: 'PDF hochgeladen',
            backgroundColor: '#e6e6e6',
            color: '#444'
        },
        OCR_DONE: {
            text: 'LLM Bewertung läuft',
            backgroundColor: '#fff9e6',
            color: '#e6ac00'
        },
        LLM_CLASSIFICATION_DONE: {
            text: 'LLM Bewertung abgeschlossen',
            backgroundColor: '#ffedb3',
            color: '#e6ac00'
        },
        FINISHED_REVIEW: {
            text: 'Anwaltliche Bewertung abgeschlossen',
            backgroundColor: '#e8fff2',
            color: '#47c78d'
        },
        FINISHED: {
            text: 'Vertrag abgeschlossen',
            backgroundColor: '#007f5f',
            color: '#fff'
        }
    };

    return (
        <Chip label={stateProperties[contractState]?.text ?? contractState} sx={{
            backgroundColor: stateProperties[contractState]?.backgroundColor ?? '#faf2e5',
            color: stateProperties[contractState]?.color ?? '#e1c055',
            border: `1px solid ${stateProperties[contractState]?.color ?? '#e1c055'}`,
            borderRadius: '8px'
        }} />
    );
};

export default WorkflowStateBadge;

// Function to get the newest human review
export function getNewestHumanReview(clause) {
    if (!clause.reviews) return null;

    return clause.reviews
        .filter((review) => review.author.role !== "llm")
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] || null;
}

// Function to get the newest LLM review
export function getNewestLLMReview(clause) {
    if (!clause.reviews) return null;

    return clause.reviews
        .filter((review) => review.author.role === "llm")
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] || null;
}

// Function to get the most relevant review
export function getMostRelevantReview(clause) {
    const newestHumanReview = getNewestHumanReview(clause);
    const newestLLMReview = getNewestLLMReview(clause);

    // If there's a human review, prioritize it
    if (newestHumanReview) {
        return newestHumanReview;
    }

    // Otherwise, return the newest LLM review
    return newestLLMReview;
}

export function getAppliedRuleVersion(rule, timestamp){
    return rule.versions.findLast((version) => version.createdAt < timestamp);
}
import React, { useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Button,
    List,
    ListItem,
    Divider,
} from "@mui/material";

const RuleModal = ({ open, onClose, rule }) => {

    if (!rule) return null;

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    maxHeight: "80vh",
                    overflowY: "auto",
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    Regelhistorie: {rule.topic}
                </Typography>
                <List>
                    {rule.versions.map((version, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <Box sx={{ width: "100%" }}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Version vom {new Date(version.createdAt).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                                        {version.text}
                                    </Typography>
                                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                        {version.references?.join(", ")}
                                    </Typography>
                                </Box>
                            </ListItem>
                            {index < rule.versions.length - 1 && <Divider sx={{ my: 2 }} />}
                        </React.Fragment>
                    ))}
                </List>
                <Box sx={{ textAlign: "right", mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        Schließen
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RuleModal;

import { Box, Button, Divider, TextField } from '@mui/material';
import React, {useContext, useState} from 'react';
import ClassificationLawyer from './ClassificationLawyer';
import { Assessment } from "../../../enums/Assessment";
import {ContractContext} from "../../../provider/ContractProvider";

const ClassificationLawyers = ({ clause }) => {
    const { addReview } = useContext(ContractContext);
    const [newClassification, setNewClassification] = useState(Assessment.VALID);
    const [newExplanation, setNewExplanation] = useState('');

    function createNewReview(){
        addReview({
            clause: clause._id,
            assessment: newClassification,
            explanation: newExplanation,
            hurt_rules: []
        });
        setNewClassification(Assessment.VALID);
        setNewExplanation('');
    }

    return (
        <Box sx={{ mx: 5, mt: 2 }}>
            {clause.reviews.map((review, index) => (
                <React.Fragment key={review._id || index}>
                    {index !== 0 && <Divider />}
                    <ClassificationLawyer review={review} />
                </React.Fragment>
            ))}

            {clause.latestReview && <Divider />}

            <Box sx={{ my: 3, display: 'flex', flexDirection: 'row' }}>
                {Object.values(Assessment).map((assessment) => (
                    <Button
                        key={assessment}
                        onClick={() => setNewClassification(assessment)}
                        sx={{
                            borderWidth: newClassification === assessment ? 2 : 1,
                            borderStyle: 'solid',
                            borderColor: newClassification === assessment ? 'primary.main' : 'transparent',
                        }}
                        variant={newClassification === assessment ? 'outlined' : 'text'}
                    >
                        <Box
                            sx={{
                                minWidth: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: `assessment.${assessment}`,
                            }}
                        />
                    </Button>
                ))}

                <TextField
                    variant="outlined"
                    label="Ihre Bewertung"
                    value={newExplanation}
                    onChange={(e) => setNewExplanation(e.target.value)}
                    sx={{ width: '100%', mx: 1 }}
                />

                <Button onClick={createNewReview} variant="contained">
                    Fertig
                </Button>
            </Box>
        </Box>
    );
};

export default ClassificationLawyers;

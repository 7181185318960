import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, {useContext, useState} from 'react';
import ConfirmActionDialog from '../../general/ConfirmActionDialog';
import {ContractContext} from "../../../provider/ContractProvider";

const OptionsMenuClause = ({ clause, handleEdit }) => {
    const { mergeClause, deleteClause, upgradeClause } = useContext(ContractContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [openDialog, setOpenDialog] = useState(false);
    const [actionToConfirm, setActionToConfirm] = useState(() => {});

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleOpenDialog = (action) => {
        setOpenDialog(true);
        setActionToConfirm(() => action);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setActionToConfirm(() => {});
    };

    const handleMerge = () => {
        handleOpenDialog(()=>{
            mergeClause(clause._id);
        });
    };

    const handleUpgrade = () => {
        handleOpenDialog(()=>{
            upgradeClause(clause._id);
        });
    };

    const handleDelete = () => {
        handleOpenDialog(()=>{
            deleteClause(clause._id);
        });
    };

    return (
        <>
            <ConfirmActionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} actionToConfirm={actionToConfirm} />
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl} keepMounted>
                {/* Conditional first menu item */}
                {clause.position !== 0 && (
                    <MenuItem
                        onClick={handleMerge}
                        sx={{ borderRadius: '8px', mx: 1, pr: 0 }}
                    >
                        <ListItemText>
                            <Typography>Mit vorheriger Klausel vereinen</Typography>
                        </ListItemText>
                        <ListItemIcon sx={{ ml: 1, mr: 0 }}>
                            <AddCircleOutlineIcon />
                        </ListItemIcon>
                    </MenuItem>
                )}

                {/* Edit clause */}
                <MenuItem
                    onClick={handleEdit}
                    sx={{ borderRadius: '8px', mx: 1, pr: 0 }}
                >
                    <ListItemText>
                        <Typography>Klausel Text bearbeiten</Typography>
                    </ListItemText>
                    <ListItemIcon sx={{ ml: 1, mr: 0 }}>
                        <EditIcon />
                    </ListItemIcon>
                </MenuItem>

                {/* Upgrade to paragraph */}
                <MenuItem
                    onClick={handleUpgrade}
                    sx={{ borderRadius: '8px', mx: 1, pr: 0 }}
                >
                    <ListItemText>
                        <Typography>Zu Paragraphen umwandeln</Typography>
                    </ListItemText>
                    <ListItemIcon sx={{ ml: 1, mr: 0 }}>
                        <FileUploadIcon />
                    </ListItemIcon>
                </MenuItem>

                {/* Delete clause */}
                <MenuItem
                    onClick={handleDelete}
                    sx={{ borderRadius: '8px', mx: 1, pr: 0 }}
                >
                    <ListItemText>
                        <Typography>Klausel löschen</Typography>
                    </ListItemText>
                    <ListItemIcon sx={{ ml: 1, mr: 0 }}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
        </>
    );
};

export default OptionsMenuClause;

import React, {useContext} from 'react';
import { Box, Button } from '@mui/material';
import { AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun } from 'docx';
import FileSaver, { saveAs } from 'file-saver';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ContractState } from '../../enums/ContractState';
import {ContractContext} from "../../provider/ContractProvider";

const DownloadContractButton = () => {
    const { contract, resetContract } = useContext(ContractContext);

    const createContractDocx = () => {
        if (contract) {
            const paragraphsInSection = contract.paragraphs.map((paragraph) => [
                new Paragraph({
                    children: [new TextRun(paragraph.content)],
                    heading: HeadingLevel.HEADING_2,
                    spacing: { after: 200 }
                }),
                ...paragraph.clauses.map((clause) => (
                    new Paragraph({
                        children: [new TextRun({
                            text: clause.alternative?.text ?? clause.content,
                            size: 24
                        })],
                        spacing: { after: 200 }
                    })
                ))
            ]).flat();

            const doc = new Document({
                sections: [{
                    properties: {},
                    children: [
                        new Paragraph({
                            children: [new TextRun('Arbeitsvertrag')],
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                            spacing: { after: 200 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: 'Der Arbeitsvertrag wird geschlossen zwischen',
                                size: 24
                            })],
                            spacing: { after: 100 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: '..................',
                                size: 24
                            })],
                            spacing: { after: 200 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: '- nachfolgend "Arbeitgeber" genannt -',
                                size: 24
                            })],
                            alignment: AlignmentType.END,
                            spacing: { after: 200 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: 'und',
                                size: 24
                            })],
                            spacing: { after: 100 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: '..................',
                                size: 24
                            })],
                            spacing: { after: 200 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: '- nachfolgend "Arbeitnehmer" genannt -',
                                size: 24
                            })],
                            alignment: AlignmentType.END,
                            spacing: { after: 200 }
                        }),
                        ...paragraphsInSection,
                        new Paragraph({
                            children: [new TextRun({
                                text: '....................................................',
                                size: 24
                            })],
                            spacing: { before: 600 }
                        }),
                        new Paragraph({
                            children: [new TextRun({
                                text: 'Ort, Datum',
                                size: 24
                            })]
                        }),
                        new Paragraph({
                            tabStops: [{
                                type: TabStopType.RIGHT,
                                position: TabStopPosition.MAX
                            }],
                            children: [
                                new TextRun({
                                    text: '....................................................',
                                    size: 24
                                }),
                                new TextRun({
                                    text: '	....................................................',
                                    size: 24
                                })
                            ],
                            spacing: { before: 600 }
                        }),
                        new Paragraph({
                            tabStops: [{
                                type: TabStopType.RIGHT,
                                position: TabStopPosition.MAX
                            }],
                            children: [
                                new TextRun({
                                    text: 'Unterschrift Arbeitgeber',
                                    size: 24
                                }),
                                new TextRun({
                                    text: '	Unterschrift Arbeitnehmer',
                                    size: 24
                                })
                            ]
                        })
                    ]
                }]
            });

            Packer.toBlob(doc).then(async (blob) => {
                saveAs(blob, `${contract.title}.docx`);
                resetContract(ContractState.FINISHED);
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    return (
        <Box>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={createContractDocx}
                endIcon={<GetAppIcon />}
            >
                Neufassung herunterladen
            </Button>
        </Box>
    );
};

export default DownloadContractButton;

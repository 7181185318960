import { Box, Card, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ContractContext } from "../../provider/ContractProvider";
import WorkflowStateBadge from "./WorkflowStateBadge";

const ContractInfo = () => {
    const { contract } = useContext(ContractContext);

    return (
        <Card sx={{ height: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 2,
                    alignItems: 'center'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Typography variant="h5" fontWeight={'bold'} color={'primary.main'} sx={{ mr: 2 }}>
                        {contract.title}
                    </Typography>
                    <WorkflowStateBadge contractState={contract.state} />
                </Box>
                <Box sx={{textAlign:"center"}}>
                    <Typography color="text.secondary">
                        {`Erstellt von: ${contract.owner.firstName + ' ' + contract.owner.lastName} am ${new Date(contract.createdAt).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}`}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default ContractInfo;
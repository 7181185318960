import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Autocomplete, Box, Button, Container, IconButton, InputBase, Paper, Typography, useTheme} from '@mui/material';
import RuleTable from "./RuleTable";
import axios from "axios";

const RuleOverview = () => {
    const [rules, setRules] = useState([]);
    const [topics, setTopics] = useState([]);
    const [search, setSearch] = useState("");

    const [open, setOpen] = useState(false);

    const [currentRule, setCurrentRule] = useState({
        title: "",
        topic: "",
        text: "",
        references: []
    });

    useEffect(() => {
        fetchTopics();
        fetchRules();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await axios.get('/topics');
            setTopics(response.data);
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };

    const fetchRules = async () => {
        try {
            const response = await axios.get('/rules');
            setRules(response.data);
        } catch (error) {
            console.error('Error fetching rules:', error);
        }
    };

    const handleCreateNewButton = () => {
        setCurrentRule({ title: '', topic: '', text: '', references: [] });
        setOpen(true);
    };

    const handleEditButton = (id, rule) => {
        const newestVersion = rule.versions[rule.versions.length - 1];
        setCurrentRule({
            _id: id,
            title: rule.title,
            text: newestVersion.text,
            references: newestVersion.references,
            topic: newestVersion.topic.title,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveEntry = async () => {
        if (currentRule._id) {
            try {
                await axios.put(`/rules/${currentRule._id}`, currentRule);
            } catch (error) {
                console.error('Error updating template:', error);
            }
        } else {
            try {
                await axios.post('/rules', currentRule);
            } catch (error) {
                console.error('Error creating template:', error);
            }
        }
        fetchRules();
        handleClose();
    };

    const handleArchive = async (rule, archive) => {
        try {
            await axios.put(`/rules/${rule._id}/archive`, {
                isArchived: archive
            });
        } catch (error) {
            console.error('Error archiving rule:', error);
        }
        fetchRules();
    };

    const handleAddReference = () => {
        setCurrentRule(prev => ({
            ...prev,
            references: [...(prev.references || []), ""]
        }));
    };

    const handleRemoveReference = (index) => {
        setCurrentRule(prev => ({
            ...prev,
            references: (prev.references || []).filter((_, i) => i !== index)
        }));
    };

    const handleReferenceChange = (index, value) => {
        setCurrentRule(prev => ({
            ...prev,
            references: (prev.references || []).map((ref, i) =>
                i === index ? value : ref
            )
        }));
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const filteredRules = rules.filter(rule => {
        const version = rule.versions[rule.versions.length - 1];
        const searchLower = search.toLowerCase();
        return (
            rule.title.toLowerCase().includes(searchLower) ||
            version.topic.title.toLowerCase().includes(searchLower) ||
            version.text.toLowerCase().includes(searchLower) ||
            version.references.some(ref => ref.toLowerCase().includes(searchLower))
        );
    });

    return (
        <Container sx={{mt: '2em'}}>
                <Typography variant="h4">Prüfanleitungen</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>{currentRule._id ? "Regel bearbeiten" : "Neue Regel-Version"}</Typography>
                            <TextField
                                fullWidth
                                size="small"
                                value={currentRule.title}
                                onChange={(e) => {
                                    setCurrentRule({
                                        ...currentRule,
                                        title: e.target.value
                                    });
                                }}
                                label={"Titel"}
                                disabled={currentRule._id}
                            />
                            <Autocomplete
                                freeSolo
                                options={topics.map(topic => topic.title)}
                                value={currentRule.topic}
                                onChange={(event, newValue) => {
                                    setCurrentRule({
                                        ...currentRule,
                                        topic: newValue
                                    });
                                }}
                                inputValue={currentRule.topic}
                                onInputChange={(event, newValue) => {
                                    setCurrentRule({
                                        ...currentRule,
                                        topic: newValue
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Thema (zB.: Vergütung)"
                                        margin="normal"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            marginBottom: '1rem',
                                            display: 'block',
                                            overflowWrap: 'break-word'
                                        }}
                                    />
                                )}
                            />
                            <TextField value={currentRule.text} label="Regeln... (zB.: Umfang von über 8 Stunden pro Monat als abgegoltene Überstunden ist unzulässig.)" multiline rows={4} fullWidth margin="normal" sx={{
                                width: '100%',
                                marginBottom: '1rem',
                                display: 'block',
                                overflowWrap: 'break-word'
                            }} onChange={(e) => {
                                const newRule = { ...currentRule };
                                newRule.text = e.target.value;
                                setCurrentRule(newRule);
                            }} />
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>Referenzen</Typography>
                                {currentRule.references?.map((reference, index) => (
                                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={reference}
                                            onChange={(e) => handleReferenceChange(index, e.target.value)}
                                            label={`Referenz ${index + 1}`}
                                        />
                                        <IconButton
                                            onClick={() => handleRemoveReference(index)}
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={handleAddReference}
                                    variant="outlined"
                                    size="small"
                                    sx={{ mt: 1 }}
                                >
                                    Mehr Referenzen
                                </Button>
                            </Box>

                            {currentRule._id &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={currentRule.isSyntactic || false}
                                            onChange={(e) => {
                                                setCurrentRule({ ...currentRule, isSyntactic: e.target.checked });
                                            }}
                                        />
                                    }
                                    label="Keine semantische Änderung"
                                />
                            }
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button type="submit" variant="contained" color="primary" onClick={() => {
                                saveEntry().catch((error) => {
                                    console.log(error);
                                });
                            }} fullWidth sx={{ width: '100%' }}>
                                Speichern
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ my: 4 }}
                            onClick={handleCreateNewButton}
                        >
                            Neue Regel
                        </Button>
                    </Box>
                    <Paper component="form" sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: 'none',
                        height: 'fit-content',
                    }}>
                        <InputBase sx={{
                            ml: 1,
                            flex: 1
                        }} placeholder="Suche eingeben" inputProps={{ 'aria-label': 'Suche eingeben' }} value={search} onChange={handleSearchChange} onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Suche">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>

                <RuleTable rules={filteredRules} handleEdit={handleEditButton} handleArchive={handleArchive} />
        </Container>
    );
};

export default RuleOverview;

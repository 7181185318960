import React, {useContext, useState} from 'react';
import { Avatar, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "../../provider/AuthProvider";

export default function UserInformation() {
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: 'center',
                    px: 2.5,
                    borderRadius: '23px'
                }}
                onClick={handleClick}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: 0,
                        justifyContent: 'center'
                    }}
                >
                    <Avatar sx={{ bgcolor: 'orange' }}>{`AB`}</Avatar>
                </ListItemIcon>
            </ListItemButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    minWidth: 100
                }}
            >
                <MenuItem
                    onClick={logout}
                >
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </ListItem>
    );
};

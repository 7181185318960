import { Box, Card, Grid } from '@mui/material';
import ClassificationAndChangeReset from './ClassificationAndChangeReset';
import ClassificationInfo from './ClassificationInfo';
import ParagraphsAndClauses from './ParagraphsAndClauses';
import PdfViewer from './PdfViewer';
import React, { useContext, useEffect, useState } from 'react';
import { ContractContext } from "../../../provider/ContractProvider";
import { Assessment } from "../../../enums/Assessment";
import { getMostRelevantReview } from "../../../helper/contractHelpers";

const ContractOcrDisplay = () => {
    const { contract } = useContext(ContractContext);
    const [filteredAssessment, setFilteredAssessment] = useState(Object.keys(Assessment).map(x => true));
    const [filteredParagraphs, setFilteredParagraphs] = useState([]);
    const [selectedClauseId, setSelectedClauseId] = useState('');
    const [executeScroll, setExecuteScroll] = useState(false);

    useEffect(() => {
        const calculateFilteredParagraphs = () => {
            return contract.paragraphs
                .map(paragraph => {
                    // Create a shallow copy of the paragraph
                    const paragraphCopy = { ...paragraph };

                    // Create a filtered, shallow copy of the clauses array
                    paragraphCopy.clauses = paragraph.clauses.filter(clause => {
                        const assessment = getMostRelevantReview(clause)?.assessment;
                        if (!assessment) return true;
                        const assessmentIndex = Object.values(Assessment).indexOf(assessment);
                        return filteredAssessment[assessmentIndex];
                    });

                    return paragraphCopy;
                })
                .filter(paragraph => paragraph.clauses.length > 0);
        };

        setFilteredParagraphs(calculateFilteredParagraphs());
    }, [contract, filteredAssessment]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <Card sx={{ width: '100%', marginBottom: 1 }}>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <ClassificationInfo filteredAssessment={filteredAssessment} setFilteredAssessment={setFilteredAssessment} />
                        <ClassificationAndChangeReset />
                    </Box>
                </Card>
                <Grid container spacing={2} justifyContent="center" sx={{ height: '75vh' }}>
                    <Grid item xs={6} md={6} lg={6} sx={{ overflow: 'hidden', height: '100%' }}>
                        <PdfViewer filteredParagraphs={filteredParagraphs} setSelectedClauseId={setSelectedClauseId} setExecuteScroll={setExecuteScroll} />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sx={{ height: '100%' }}>
                        <ParagraphsAndClauses filteredParagraphs={filteredParagraphs} selectedClauseId={selectedClauseId} setSelectedClauseId={setSelectedClauseId} executeScroll={executeScroll} setExecuteScroll={setExecuteScroll} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ContractOcrDisplay;

import SaveIcon from '@mui/icons-material/Save';
import { Card, Divider, IconButton, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TrafficLightIndication from './TrafficLightIndication';
import OptionsMenuClause from './OptionsMenuClause';
import OptionsMenuParagraph from './OptionsMenuParagraph';
import ClassificationLawyers from './ClassificationLawyers';
import {ContractContext} from "../../../provider/ContractProvider";

const ParagraphsAndClauses = ({ filteredParagraphs, selectedClauseId, setSelectedClauseId, executeScroll, setExecuteScroll }) => {
    const { changeClause, changeParagraph } = useContext(ContractContext);
    const [selectedParagraphId, setSelectedParagraphId] = useState('');
    const [editClauseId, setEditClauseId] = useState('');
    const [newClauseText, setNewClauseText] = useState('');
    const [editParagraphId, setEditParagraphId] = useState('');
    const [newParagraphText, setNewParagraphText] = useState('');

    const containerRef = useRef(null);
    const clauseRefs = useRef({});

    useEffect(() => {
        filteredParagraphs.forEach(paragraph => {
            paragraph.clauses.forEach(clause => {
                clauseRefs.current[clause._id] = clauseRefs.current[clause._id] || React.createRef();
            });
        });
    }, [filteredParagraphs]);

    useEffect(() => {
        if (executeScroll) {
            scrollToClause(selectedClauseId);
            setExecuteScroll(false);
        }
    }, [selectedClauseId, executeScroll]);

    const scrollToClause = (clauseId) => {
        const clauseElement = clauseRefs.current[clauseId].current;
        clauseElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const handleClauseEdit = () => {
        setEditClauseId(selectedClauseId);
        setNewClauseText('');
    }

    const handleParagraphEdit = () => {
        setEditParagraphId(selectedParagraphId);
        setNewParagraphText('');
    }

    const handleClauseChange = async () => {
        changeClause(editClauseId, newClauseText);
        setEditClauseId('');
    };

    const handleParagraphChange = async () => {
        changeParagraph(editParagraphId, newParagraphText);
        setEditParagraphId('');
    };

    return (
        <Box ref={containerRef} sx={{ overflow: 'auto', height: '100%' }}>
            {filteredParagraphs.map(paragraph => (
                <Card
                    sx={{
                        padding: 2,
                        margin: 2,
                        boxShadow: 'none',
                        border: '2px solid',
                        scrollMarginTop: '10px',
                        borderColor: paragraph._id === selectedParagraphId ? 'lightgrey' : 'transparent',
                        transition: 'transform 0.3s ease',
                        '&:hover': { transform: 'scale(1.007)' },
                    }}
                    key={paragraph._id}
                    onClick={() => setSelectedParagraphId(paragraph._id)}
                >
                    {editParagraphId === paragraph._id ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                variant="standard"
                                sx={{ width: '100%' }}
                                defaultValue={paragraph.content}
                                onChange={(e) => setNewParagraphText(e.target.value)}
                            />
                            <IconButton
                                onClick={handleParagraphChange}
                            >
                                <SaveIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', width: '100%' }}>{paragraph.content}</Typography>
                            {selectedParagraphId === paragraph._id && <OptionsMenuParagraph paragraph={paragraph} handleEdit={handleParagraphEdit} />}
                        </Box>
                    )}
                    {paragraph.clauses.map((clause, index2) => (
                        <Box key={clause._id}>
                            {index2 !== 0 && <Divider />}
                            <Card
                                ref={clauseRefs.current[clause._id]}
                                sx={{
                                    padding: 0.5,
                                    my: 0.5,
                                    width: '100%',
                                    backgroundColor: clause._id === selectedClauseId ? '#F0F8FF' : 'transparent',
                                }}
                                onClick={() => setSelectedClauseId(clause._id)}
                            >
                                {editClauseId === clause._id ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            variant="outlined"
                                            sx={{ marginTop: 0.5, width: '100%' }}
                                            defaultValue={clause.content}
                                            onChange={(e) => setNewClauseText(e.target.value)}
                                        />
                                        <IconButton
                                            onClick={handleClauseChange}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TrafficLightIndication clause={clause} />
                                        <Typography sx={{ width: '100%', cursor: 'pointer', padding: 1, ml: 1 }}>
                                            {clause.content}
                                        </Typography>
                                        {selectedClauseId === clause._id && <OptionsMenuClause clause={clause} handleEdit={handleClauseEdit} />}
                                    </Box>
                                )}
                                {clause._id === selectedClauseId && (
                                    <Box>
                                        <Divider sx={{ margin: 1 }} />
                                        <ClassificationLawyers clause={clause} />
                                    </Box>
                                )}
                            </Card>
                        </Box>
                    ))}
                </Card>
            ))}
        </Box>
    );
};

export default ParagraphsAndClauses;

import React from 'react';
import { Box, Typography } from '@mui/material';
import LoadingSpinner from './LoadingSpinner';

const LoadingComponent = ({ text }) => {
    return (
        <Box sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box mb={1}>
                <LoadingSpinner />
            </Box>
            <Typography>{text}</Typography>
        </Box>
    );
};

export default LoadingComponent;

import { Box, Button, Card, Typography } from '@mui/material';
import React, {useState, useEffect, useContext} from 'react';
import {Assessment} from "../../../enums/Assessment";
import {getMostRelevantReview} from "../../../helper/contractHelpers";
import {ContractContext} from "../../../provider/ContractProvider";

const ClassificationInfo = ({ filteredAssessment, setFilteredAssessment }) => {
    const { contract } = useContext(ContractContext);
    const assessmentTitle = ['In Ordnung', 'Unfair', 'Unzulässig'];
    const [assessmentCounts, setAssessmentCounts] = useState({});

    useEffect(() => {
        const counts = Object.values(Assessment).reduce((acc, assessment) => {
            acc[assessment] = 0;
            return acc;
        }, {});

        contract.paragraphs.forEach((paragraph) => {
            if (paragraph.clauses && Array.isArray(paragraph.clauses)) {
                paragraph.clauses.forEach((clause) => {
                    const mostRelevantReview = getMostRelevantReview(clause);
                    if (mostRelevantReview) counts[mostRelevantReview.assessment] += 1;
                });
            }
        });

        setAssessmentCounts(counts);
    }, [contract])

    return (
        <Card
            sx={{
                marginLeft: 1,
                p: 1.5,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                }}
            >
                {Object.values(Assessment).map((assessmentType, index) => (
                    <Button
                        key={assessmentType}
                        onClick={() => {
                            const newFilteredAssessment = filteredAssessment.map((item, currentIndex) =>
                                currentIndex === index ? !item : item
                            );
                            setFilteredAssessment(newFilteredAssessment);
                        }}
                        sx={{
                            flex: '1 1 auto',
                            mr: 1,
                            ml: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 0.5,
                            borderWidth: filteredAssessment[index] ? 1 : 1,
                            borderStyle: 'solid',
                            borderColor: filteredAssessment[index] ? 'primary.main' : 'lightgrey',
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                            borderRadius: 10,
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 'bold',
                                width: '80%',
                                ml: 1,
                            }}
                        >
                            {assessmentTitle[index]}
                        </Typography>
                        <Card
                            sx={{
                                backgroundColor: `assessment.${assessmentType.toLowerCase()}`,
                                width: '20%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '4px 8px',
                                borderRadius: 8,
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                {assessmentCounts[assessmentType]}
                            </Typography>
                        </Card>
                    </Button>
                ))}
            </Box>
        </Card>
    );
};

export default ClassificationInfo;

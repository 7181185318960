import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const RulesPerTopicChart = ({ topics, rules }) => {
    const theme = useTheme();

    const processChartData = () => {
        const topicCounts = {};

        // Initialize counts for all topics to 0
        topics.forEach(topic => {
            topicCounts[topic._id] = 0;
        });

        // Count rules per topic
        rules.forEach(rule => {
            topicCounts[rule.versions[rule.versions.length-1].topic]++;
        });

        const topicNames = topics.map(topic => topic.title);
        const ruleCounts = topics.map(topic => topicCounts[topic._id]);

        return {
            series: [{ name: "Rules", data: ruleCounts }],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: { show: false }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                xaxis: {
                    categories: topicNames,
                    title: { text: 'Number of Rules' }
                },
                yaxis: {
                    title: { text: 'Topic' }
                },
                colors: [theme.palette.secondary.main], // Use a different color
                dataLabels: { enabled: false },
                title: {
                    text: 'Rules per Topic',
                    align: 'center'
                }
            }
        };
    };

    const chartData = processChartData();

    return (
        <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={30*topics.length}
        />
    );
};

export default RulesPerTopicChart;
import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts'; // Import ApexCharts
import { Assessment } from "../../../enums/Assessment";
import {Typography} from "@mui/material";

const PrecisionByRule = ({ reviews, rules }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (!reviews || !rules) return;

        const groupedReviews = reviews.reduce((acc, review) => {
            if (!acc[review.clause]) {
                acc[review.clause] = [];
            }
            acc[review.clause].push(review);
            return acc;
        }, {});

        const ruleDict = {};
        rules.forEach((rule) => (ruleDict[rule._id] = rule));

        const ruleSeriesData = {};

        // Find the longest version history
        const longestVersionHistory = Math.max(...rules.map((rule) => rule.versions.length));

        for (const rule of rules) {
            const series = Array.from({ length: longestVersionHistory }, () => ({
                correct: 0,
                incorrect: 0,
            }));

            for (const clauseReviews of Object.values(groupedReviews)) {
                const llmReview = clauseReviews.filter(
                    (review) => review.author.kind === 'LLM'
                ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];
                const humanReview = clauseReviews.filter(
                    (review) => review.author.kind !== 'LLM'
                ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

                if (!llmReview || llmReview.assessment !== Assessment.VOID || llmReview.hurt_rules.length === 0)
                    continue;

                const predictedLabel = llmReview.assessment;
                const trueLabel = humanReview ? humanReview.assessment : predictedLabel;

                llmReview.hurt_rules.filter((ruleId) => ruleId === rule._id).forEach((ruleId) => {
                    const rule = ruleDict[ruleId];
                    const activeVersionIndex = rule.versions.findLastIndex(
                        (version) => version.createdAt < llmReview.createdAt
                    );
                    if (predictedLabel === trueLabel) {
                        series[activeVersionIndex].correct++;
                    } else {
                        series[activeVersionIndex].incorrect++;
                    }
                });
            }

            ruleSeriesData[rule._id] = series;
        }

        const calculatedChartData = Object.entries(ruleSeriesData).map(([ruleId, versions]) => {
            const precisions = versions.map((version) => {
                const total = version.correct + version.incorrect;
                return total === 0 ? null : version.correct / total;
            });
            return {
                name: ruleDict[ruleId].title,
                data: precisions,
            };
        }).filter(x=>x.data.filter(y=>y!=null).length > 0);

        setChartData(calculatedChartData);
    }, [reviews, rules]);

    if (!chartData) {
        return <></>;
    }

    return (
        <div>
            <h2>Precision by rule</h2>
            <p>This chart shows the precision of the AI by each rule.</p>
            {chartData.length > 0 && chartData[0].length > 0 ?
                <ApexCharts options={{
                    labels: Array.from({ length: chartData[0].data.length }, (_, i) => `Version ${i + 1}`),
                    yaxis: {
                        min: 0,
                        max: 1,
                        labels: {
                            formatter: (val) => `${(val * 100).toFixed(2)}%`,
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: (val) => `${(val * 100).toFixed(2)}%`,
                        },
                    },
                    markers: {
                        size: 5,
                    }
                }} series={chartData} type="line" height={350} /> : <Typography>Not enough data.</Typography>
            }
        </div>
    );
};

export default PrecisionByRule;
import { Box, Card, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../contractManagement/SearchBar';
import ContractItem from './ContractItem';
import UploadCard from './UploadCard';
import axios from "axios";

const ContractsOverview = () => {
    const navigate = useNavigate();
    const [contracts, setContracts] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const fetchedContracts = await axios.get("contracts/");
                setContracts(fetchedContracts.data);
            } catch (error) {
                console.error('Failed to fetch contracts:', error);
            }
        };

        fetchContracts();
    }, []);

    useEffect(() => {
        if (searchString === '' || contracts==null) {
            setFilteredContracts(contracts);
        } else {
            setFilteredContracts(
                contracts.filter((contract) => {
                    const titleMatch = contract.title.toLowerCase().includes(searchString.toLowerCase());
                    const descriptionMatch = contract.description.toLowerCase().includes(searchString.toLowerCase());
                    const industryMatch = contract.industry.toLowerCase().includes(searchString.toLowerCase());
                    return titleMatch || descriptionMatch || industryMatch;
                })
            );
        }
    }, [searchString, contracts]);

    async function handleDelete(contractId){
        try {
            await axios.delete(`contracts/${contractId}`);
            setContracts((prevContracts) => prevContracts.filter(contract => contract._id !== contractId));
        } catch (error) {
            console.error(error);
        }
    }

    async function handleReset(contractId, targetState){
        try {
            await axios.patch(`contracts/reset/${contractId}`, {targetState});
            setContracts((prevContracts) =>
                prevContracts.map(contract =>
                    contract._id === contractId ? { ...contract, state: targetState } : contract
                )
            );
        } catch (error) {
            console.error(error);
        }
    }

    function addContract(contract){
        setFilteredContracts([...contracts, contract]);
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                overflowY: 'auto',
                maxHeight: '100vh',
                width: '100%'
            }}>
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={2} justifyContent={'left'} my={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography variant='h4'>
                            Übersicht Verträge
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <SearchBar searchString={searchString} changeSearchString={setSearchString} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent={'left'}>
                    <Grid
                        item
                        xs={4}
                        md={6}
                        lg={4}
                        xl={3}
                        sx={{
                            display: 'flex',
                            justifyContent: 'stretch'
                        }}
                    >
                        <Card
                            sx={{
                                p: 1,
                                backgroundColor: 'tertiary.main',
                                width: '100%'
                            }}
                        >
                            <UploadCard addContract={addContract} />
                        </Card>
                    </Grid>
                    {filteredContracts.map((contract, index) => (
                        <Grid
                            item
                            xs={4}
                            md={6}
                            lg={4}
                            xl={3}
                            key={index}
                            onClick={() => {
                                navigate(`/contracts/${contract._id}`, { state: { contract } })
                            }}
                        >
                            <ContractItem key={contract._id} contract={contract} handleDelete={handleDelete} handleReset={handleReset} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ContractsOverview;
import React, {useContext, useState} from 'react';
import { Box, Typography, Divider, IconButton, TextField } from '@mui/material';
import {Edit, Save, Cancel, SwapHoriz, Error, History, Update} from '@mui/icons-material';
import TrafficLightIndication from "../classification/TrafficLightIndication";
import {ContractContext} from "../../../provider/ContractProvider";
import {getMostRelevantReview} from "../../../helper/contractHelpers";
import {Assessment} from "../../../enums/Assessment";

const ClauseItem = ({ clause, selectedClauseId, handleClauseClick }) => {
    const { setAlternative } = useContext(ContractContext);
    const [showOriginal, setShowOriginal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState(clause.alternative?.text || '');

    const handleToggleView = () => {
        setShowOriginal(!showOriginal);
    };

    const handleEditClick = () => {
        setEditText(clause.alternative.text);
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        try {
            await setAlternative(clause._id, clause.alternative.templateClauseId, editText);
            setIsEditing(false);
        } catch (error) {
            console.error("Fehler beim Aktualisieren der Alternative:", error);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    return (
        <Box
            key={clause.id}
            sx={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '8px',
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: selectedClauseId === clause._id ? '#f5f5f5' : 'inherit',
                cursor: 'pointer',
            }}
            onClick={() => handleClauseClick(clause)}
        >
            <TrafficLightIndication clause={clause} />
            <Box sx={{ marginLeft: '8px', flexGrow: 1 }}>
                {
                    isEditing ? (
                        <TextField
                            fullWidth
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                            variant="outlined"
                            size="small"
                            multiline
                        />
                    ) : (
                        <Typography variant="body1">
                            {showOriginal || !clause.alternative ? clause.content : clause.alternative.text}
                        </Typography>
                    )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', ml: 2 }}>
                {clause.alternative && !isEditing && (
                    <IconButton size="small" onClick={handleToggleView}>
                        {showOriginal ? <Update /> : <History />}
                    </IconButton>
                )}
                {clause.alternative && !showOriginal && !isEditing && (
                    <IconButton size="small" onClick={handleEditClick} aria-label="Alternative bearbeiten">
                        <Edit />
                    </IconButton>
                )}
                {isEditing && (
                    <>
                        <IconButton size="small" onClick={handleSaveClick} aria-label="Änderungen speichern">
                            <Save />
                        </IconButton>
                        <IconButton size="small" onClick={handleCancelEdit} aria-label="Bearbeitung abbrechen">
                            <Cancel />
                        </IconButton>
                    </>
                )}
                {!clause.alternative && getMostRelevantReview(clause)?.assessment!==Assessment.VALID && (
                    <Error />
                )}
            </Box>
        </Box>
    );
};

export default ClauseItem;
export const ContractState = {
    PDF_UPLOADED: 'PDF_UPLOADED',
    OCR_DONE: 'OCR_DONE',
    LLM_CLASSIFICATION_DONE: 'LLM_CLASSIFICATION_DONE',
    FINISHED_REVIEW: 'FINISHED_REVIEW',
    FINISHED: 'FINISHED',
};

export function isStateAbove(state, comparison){
    if (state===comparison) return true;

    switch (state){
        case ContractState.OCR_DONE:
            return comparison===ContractState.PDF_UPLOADED;
        case ContractState.LLM_CLASSIFICATION_DONE:
            return comparison===ContractState.PDF_UPLOADED || comparison===ContractState.OCR_DONE;
        case ContractState.FINISHED_REVIEW:
            return comparison===ContractState.PDF_UPLOADED || comparison===ContractState.OCR_DONE || comparison===ContractState.LLM_CLASSIFICATION_DONE;
        case ContractState.FINISHED:
            return comparison===ContractState.PDF_UPLOADED || comparison===ContractState.OCR_DONE || comparison===ContractState.LLM_CLASSIFICATION_DONE || comparison===ContractState.FINISHED_REVIEW;
    }

    return false;
}
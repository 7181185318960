import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {ContractState, isStateAbove} from "../../enums/ContractState";
import axios from 'axios';

const OptionsMenu = ({ contract, handleReset, handleDelete }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [resetAnchorEl, setResetAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const resetOpen = Boolean(resetAnchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setResetAnchorEl(null);
    };

    const handleResetClick = (event) => {
        event.stopPropagation();
        setResetAnchorEl(event.currentTarget);
    };

    async function downloadContractPDF() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/contracts/files/${contract?.file}`, {
                responseType: 'blob'
            });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = contract.title+".pdf"; // Set the file name
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // Clean up the URL
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    }

    return (
        <>
            <IconButton
                id="options-button"
                aria-controls={open ? 'options-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="options-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'options-button' }}
            >
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(contract._id);
                    handleClose(e);
                }}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Löschen</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleResetClick}>
                    <ListItemIcon>
                        <CachedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Zurücksetzen</ListItemText>
                    <ArrowRightIcon fontSize="small" />
                </MenuItem>
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    downloadContractPDF();
                    handleClose(e);
                }}>
                    <ListItemIcon>
                        <FileDownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Download</ListItemText>
                </MenuItem>
            </Menu>
            <Menu
                id="reset-menu"
                anchorEl={resetAnchorEl}
                open={resetOpen}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'reset-button' }}
            >
                <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(contract._id, ContractState.PDF_UPLOADED); handleClose(e);}}>
                    <ListItemText>Alles zurücksetzen</ListItemText>
                </MenuItem>
                {isStateAbove(contract.state, ContractState.OCR_DONE) &&
                    <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(contract._id, ContractState.OCR_DONE); handleClose(e);}}>
                        <ListItemText>Reviews zurücksetzen</ListItemText>
                    </MenuItem>
                }
                {isStateAbove(contract.state, ContractState.FINISHED_REVIEW) &&
                    <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(contract._id, ContractState.LLM_CLASSIFICATION_DONE); handleClose(e);}}>
                        <ListItemText>Anwaltlich abgenommen zurücksetzen</ListItemText>
                    </MenuItem>
                }
                {isStateAbove(contract.state, ContractState.FINISHED) &&
                    <MenuItem onClick={(e) => {e.stopPropagation(); handleReset(contract._id, ContractState.FINISHED_REVIEW); handleClose(e);}}>
                        <ListItemText>Abgeschlossen zurücksetzen</ListItemText>
                    </MenuItem>
                }
            </Menu>
        </>
    );
}

export default OptionsMenu;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import ConfusionMatrix from "./ConfusionMatrix";
import PrecisionByRule from "./PrecisionByRule";

const AiPerformanceTab = () => {
    const [reviews, setReviews] = useState(null);
    const [rules, setRules] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/reviews');
                setReviews(response.data);

                const response2 = await axios.get('/rules');
                setRules(response2.data);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, []);

    return (
        <Box>
            <Box sx={{mt: '2em'}}>
                <PrecisionByRule reviews={reviews} rules={rules}></PrecisionByRule>
            </Box>
            <Box sx={{mt: '2em'}}>
                <ConfusionMatrix reviews={reviews}></ConfusionMatrix>
            </Box>
        </Box>
    );
};

export default AiPerformanceTab;

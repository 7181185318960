import { Box, Card, Grid, Typography, Divider, Button } from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import { ContractContext } from '../../../provider/ContractProvider';
import axios from 'axios';
import LoadingComponent from "../../general/LoadingComponent";
import ClauseItem from "./ClauseItem";

const AlternativePage = () => {
    const { contract, setAlternative } = useContext(ContractContext);
    const [selectedClause, setSelectedClause] = useState('');
    const [templateClauses, setTemplateClauses] = useState(null);

    useEffect(() => {
        if (contract.paragraphs && contract.paragraphs.length > 0 && contract.paragraphs[0].clauses.length > 0) {
            handleClauseClick(contract.paragraphs[0].clauses[0]);
        }
    }, []);

    const handleClauseClick = async (clause) => {
        if (selectedClause?._id === clause._id) return;

        setSelectedClause(clause);
        try {
            setTemplateClauses(null);
            const response = await axios.post('/templateclauses/findClosest', { query: clause.content });
            setTemplateClauses(response.data);
        } catch (error) {
            console.error('Error fetching template clauses:', error);
        }
    };

    return (
        <Box sx={{marginTop: '2em'}}>
            <Grid container spacing={2}>
                <Grid item xs={6} sx={{ height: '80vh', overflow: 'auto' }}>
                    {contract?.paragraphs?.map((paragraph, index) => (
                        <Card key={index} style={{ marginBottom: '16px', padding: '16px' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                {paragraph.content}
                            </Typography>
                            {paragraph.clauses.map((clause) => (
                                <ClauseItem key={clause._id} clause={clause} selectedClauseId={selectedClause._id} handleClauseClick={handleClauseClick}></ClauseItem>
                            ))}
                        </Card>
                    ))}
                </Grid>
                <Grid item xs={6} sx={{ height: '80vh', overflow: 'auto' }}>
                    {templateClauses ? templateClauses.map((templateClause, index) => (
                        <Card key={index} style={{ marginBottom: '16px', padding: '16px' }}>
                            <Typography variant="body1">{templateClause.text}</Typography>
                            <Button
                                color="primary"
                                variant="outlined"
                                style={{ marginTop: '8px' }}
                                onClick={() => setAlternative(selectedClause._id, templateClause._id, templateClause.text)}
                            >
                                Apply
                            </Button>
                        </Card>
                    )) : <LoadingComponent/>}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AlternativePage;
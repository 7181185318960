import { Box, Button, Card } from '@mui/material';
import * as pdfjsLib from 'pdfjs-dist';
import React, {useContext, useEffect, useRef, useState} from 'react';
import LoadingSpinner from '../../general/LoadingSpinner';
import {ContractContext} from "../../../provider/ContractProvider";
import {getHighlights} from "../../../helper/processOCR";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

export default function PdfViewer({filteredParagraphs, setSelectedClauseId, setExecuteScroll}) {
    const { contract } = useContext(ContractContext);
    const [pdf, setPDF] = useState(null);
    const [pageNum, setCurrentPage] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        async function loadPdf() {
            try {
                const options = {
                    url: process.env.REACT_APP_API_BASE_URL + '/contracts/files/' + contract.file,
                    withCredentials: true,
                    httpHeaders: {
                        mode: 'cors',
                        authorization: 'Bearer ' + localStorage.getItem("jwtToken"),
                    }
                };
                const loadingTask = pdfjsLib.getDocument(options);
                const loadedPdf = await loadingTask.promise;
                setPDF(loadedPdf);
            } catch (error) {
                console.error('Error while loading PDF: ', error);
            }
        }

        loadPdf();
    }, []);

    useEffect(() => {
        renderCurrentPage();
    }, [pageNum, pdf, filteredParagraphs]);

    async function renderCurrentPage() {
        if (!pdf) return;

        const highlights = getHighlights(pageNum, filteredParagraphs, contract.pageWidths, contract.pageHeights);
        const page = await pdf.getPage(pageNum);

        if (containerRef.current == null) return;
        while (containerRef.current.firstChild) {
            containerRef.current.removeChild(containerRef.current.firstChild);
        }
        const canvas = document.createElement('canvas');

        containerRef.current.appendChild(canvas);
        const oldViewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext('2d');

        const width = containerRef.current.clientWidth;
        const pdfScale = width / oldViewport.width;
        const viewport = page.getViewport({ scale: pdfScale });

        canvas.style.width = `${width}px`;
        canvas.style.height = `${viewport.height}px`;
        canvas.width = width;
        canvas.height = viewport.height;

        if (context == null) return;
        await page.render({
            canvasContext: context,
            viewport: viewport
        }).promise.then(() => {
            try {
                if (highlights.length > 0) {
                    highlights.forEach(highlight => {
                        context.fillStyle = highlight.color;
                        context.fillRect(highlight.x * canvas.width, highlight.y * canvas.height, highlight.width * canvas.width, highlight.height * canvas.height);
                    });

                    /* ADD CLICKLISTENER TO AREAS OF RECTANGLE */
                    canvas.addEventListener('click', function (event) {
                        const x = event.offsetX;
                        const y = event.offsetY;

                        highlights.forEach(highlight => {
                            if (x > highlight.x * canvas.width && x < highlight.x * canvas.width + highlight.width * canvas.width && y > highlight.y * canvas.height && y < highlight.y * canvas.height + highlight.height * canvas.height) {
                                onHighlightClick(highlight.clauseId);
                            }
                        });
                    });

                    /* CHANGE CURSOR TO POINTER IF MOVED OVER RECTANGLE */
                    canvas.addEventListener('mousemove', function (event) {
                        const x = event.offsetX;
                        const y = event.offsetY;

                        let cursor = 'default';

                        highlights.forEach(highlight => {
                            if (x > highlight.x * canvas.width && x < highlight.x * canvas.width + highlight.width * canvas.width && y > highlight.y * canvas.height && y < highlight.y * canvas.height + highlight.height * canvas.height) {
                                cursor = 'pointer';
                            }
                        });
                        canvas.style.cursor = cursor;
                    });
                }
            } catch (error) {
                console.error('Error changing highlights: ', error);
            }
        }).catch((error) => {
            console.error('Error rendering page: ', error);
        });
    }

    const onHighlightClick = (clauseId) => {
        setSelectedClauseId(clauseId);
        setExecuteScroll(true);
    };

    return (
        <>{pdf ?
            <Card sx={{
                boxSizing: 'border-box',
                mb: 2,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div ref={containerRef}
                     style={{
                         flex: 1,
                         width: '100%',
                         height: '100%',
                         overflowY: 'scroll'
                     }}>
                </div>
                <Box sx={{
                    height: '40px',
                    minHeight: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button variant="text" onClick={() => {
                        setCurrentPage(Math.max(pageNum - 1, 1));
                    }} disabled={pageNum === 1} style={{
                        fontSize: 'large',
                        padding: '0px',
                        borderRadius: '4px',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px'
                    }}> ‹ </Button>
                    <Box sx={{ mx: 1 }}>Seite {pageNum}</Box>
                    <Button variant="text" onClick={() => {
                        setCurrentPage(Math.min(pageNum + 1, pdf.numPages));
                    }} disabled={pageNum === pdf.numPages} style={{
                        fontSize: 'large',
                        padding: '0px',
                        borderRadius: '4px',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px'
                    }}> › </Button>
                </Box>
            </Card> :
            <LoadingSpinner />}
        </>
    );
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    InputBase,
    Paper,
    Typography,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Container
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const TemplateClauseOverview = () => {
    const [topics, setTopics] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [search, setSearch] = useState("");

    const [open, setOpen] = useState(false);
    const [currentTemplateClause, setCurrentTemplateClause] = useState({
        topic: "",
        text: ""
    });

    useEffect(() => {
        fetchTopics();
        fetchTemplates();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await axios.get('/topics');
            setTopics(response.data);
        } catch (error) {
            console.error('Error fetching topics:', error);
        }
    };

    const fetchTemplates = async () => {
        try {
            const response = await axios.get('/templateClauses');
            setTemplates(response.data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    const handleCreateNewButton = () => {
        setCurrentTemplateClause({ topic: '', text: '' });
        setOpen(true);
    };

    const handleEdit = (templateClause) => {
        setCurrentTemplateClause({
            _id: templateClause._id,
            text: templateClause.text,
            topic: templateClause.topic.title,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const saveEntry = async () => {
        if (currentTemplateClause._id) {
            try {
                await axios.put(`/templateClauses/${currentTemplateClause._id}`, currentTemplateClause);
            } catch (error) {
                console.error('Error updating template:', error);
            }
        } else {
            try {
                await axios.post('/templateClauses', currentTemplateClause);
            } catch (error) {
                console.error('Error creating template:', error);
            }
        }
        fetchTemplates();
        handleClose();
    };

    const handleDelete = async (templateClause) => {
        try {
            await axios.delete(`/templateClauses/${templateClause._id}`);
        } catch (error) {
            console.error('Error deleting template:', error);
        }
        fetchTemplates();
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const filteredTemplates = templates.filter(template => {
        const searchLower = search.toLowerCase();
        return (
            template.topic.title.toLowerCase().includes(searchLower) ||
            template.text.toLowerCase().includes(searchLower)
        );
    });

    return (
        <Container sx={{mt: '2em'}}>
                <Typography variant="h4">Musterklauseln</Typography>

                <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
                    <DialogContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            width: '100%',
                            margin: 'auto'
                        }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
                                {currentTemplateClause._id ? "Musterklausel bearbeiten" : "Neue Musterklausel erstellen"}
                            </Typography>
                            <Autocomplete
                                freeSolo
                                options={topics.map(topic => topic.title)}
                                value={currentTemplateClause.topic}
                                onChange={(event, newValue) => {
                                    setCurrentTemplateClause({
                                        ...currentTemplateClause,
                                        topic: newValue
                                    });
                                }}
                                inputValue={currentTemplateClause.topic}
                                onInputChange={(event, newValue) => {
                                    setCurrentTemplateClause({
                                        ...currentTemplateClause,
                                        topic: newValue
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Thema (zB.: Vergütung)"
                                        margin="normal"
                                        fullWidth
                                        sx={{
                                            width: '100%',
                                            marginBottom: '1rem',
                                            display: 'block',
                                            overflowWrap: 'break-word'
                                        }}
                                    />
                                )}
                            />
                            <TextField
                                value={currentTemplateClause.text}
                                label="Musterklausel..."
                                multiline
                                rows={4}
                                fullWidth
                                margin="normal"
                                sx={{
                                    width: '100%',
                                    marginBottom: '1rem',
                                    display: 'block',
                                    overflowWrap: 'break-word'
                                }}
                                onChange={(e) => setCurrentTemplateClause({
                                    ...currentTemplateClause,
                                    text: e.target.value
                                })}
                            />
                        </Box>

                        <Box sx={{
                            width: '100%',
                            maxWidth: '300px',
                            margin: 'auto',
                            marginTop: '2rem'
                        }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => saveEntry()}
                                fullWidth
                            >
                                Speichern
                            </Button>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        sx={{ my: 4 }}
                        onClick={handleCreateNewButton}
                    >
                        Neue Musterklausel
                    </Button>
                    <Paper
                        component="form"
                        sx={{
                            p: '2px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f0f0f0',
                            boxShadow: 'none',
                            height: 'fit-content'
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Suche eingeben"
                            inputProps={{ 'aria-label': 'Suche eingeben' }}
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="Suche">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>

                <TableContainer component={Paper} sx={{ mb: '2em' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{
                                backgroundColor: "#fcfcfc",
                            }}>
                                <TableCell width="15%">
                                    <Box
                                        sx={{
                                            textAlign: "left",
                                        }}
                                    >
                                        Thema
                                    </Box>
                                </TableCell>
                                <TableCell width="70%">
                                    <Box
                                        sx={{
                                            textAlign: "left",
                                        }}
                                    >
                                        Musterklausel
                                    </Box>
                                </TableCell>
                                <TableCell width="15%">
                                    <Box
                                        sx={{
                                            textAlign: "right",
                                        }}></Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTemplates.map((templateClause) => (
                                <TableRow key={templateClause._id}>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                textAlign: "left",
                                            }}
                                        >
                                            <Typography>{templateClause.topic.title}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                textAlign: "left",
                                            }}
                                        >
                                            <Typography style={{ whiteSpace: "pre-line" }}>
                                                {templateClause.text}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ textAlign: "right", display: "flex", flexBasis: '1', justifyContent: "right" }}>
                                            <Tooltip title="Bearbeiten" placement="top">
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => handleEdit(templateClause)}
                                                    sx={{
                                                        minWidth: "auto",
                                                        "&:hover": {
                                                            color: "blue",
                                                        },
                                                    }}
                                                >
                                                    <EditIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Löschen" placement="top">
                                                <Button
                                                    variant="text"
                                                    onClick={() => handleDelete(templateClause)}
                                                    sx={{
                                                        color: "red",
                                                        minWidth: "auto",
                                                        "&:hover": {
                                                            color: "darkred",
                                                        },
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </Container>
    );
};

export default TemplateClauseOverview;
